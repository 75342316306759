import { useAuth } from '@contexts/auth'
import { useFreshChat } from '@hooks/useFreshChat'
import { device, size } from '@util/responsive'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { Car } from './icons/Car'
import { HamburgerMenu } from './icons/HamburgerMenu'
import { LanguageGlobe } from './icons/LanguageGlobe'
import { Login } from './icons/Login'
import { MiniArrowRight } from './icons/MiniArrowRight'
import { Poi } from './icons/Poi'
import { Search } from './icons/Search'
import { Support } from './icons/Support'
import LangSwitcher from './LangSwitcher'
import { Cross } from './icons/Cross'
import Link from 'next/link'
import { trackEvent } from '@util/ga'
import { MiniArrowLeft } from './icons/MiniArrowLeft'
import { placeholderAvatarImage } from '@util/config'
import { useSearch } from '@contexts/search'
import { useFeature } from '@hooks/useFeatures'
import { ARABIC_LANG_FOR_ONLY_INTERNAL } from '@util/featureFlags'
import { useLanguage } from '@hooks/useLanguage'
import { LoginDesktop } from './icons/LoginDesktop'
import { useScroll } from '@hooks/useScroll'
import { MeResponse } from '@service/identity.types'
import CitySwitcher from './CitySwitcher'
import Logo from './Logo'
import { useLocaleConfig } from '@contexts/config'
import LogoRTL from '@global/LogoRTL'
import {
    MobileDropDownIconContainer,
    MobileDropdownItem,
    MobileDropDownItemContainer,
    MobileDropDownContentContainer,
    MobileDropDownMenuText,
    MobileDropDown,
} from './styled'
import { useRouter } from '@hooks/useRouter'
import { useCitySelector } from './useCitySelector'
import { GATrackingActions, GATrackingCategories, Pages } from '@util/enums'

type NavigationProps = {
    menuButtonEnabled?: boolean
    showBackButton?: boolean
}

export const Navigation: React.FC<NavigationProps> = ({ menuButtonEnabled = true, showBackButton = true }) => {
    const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
    const [isMobileCitySwitcherOpen, setIsMobileCitySwitcherOpen] = useState(false)
    const [isMobileLanguageSwitcherOpen, setIsMobileLanguageSwitcherOpen] = useState(false)
    const { isRTL } = useLocaleConfig()
    const { query, back, pushWithCity, isPage } = useRouter()
    const { isCareem } = useAuth()
    const { enabled: isArabicLangForInternalEnabled } = useFeature(ARABIC_LANG_FOR_ONLY_INTERNAL)
    const queriedCity = query.city as string
    const { datePickerOverlay, setDatepickerOverlay } = useSearch()

    const backHandler = () => {
        back()
    }
    const { scrollDirection } = useScroll()
    const { user: userData } = useAuth()

    const isLanguageSwitchDisplayed = isArabicLangForInternalEnabled && !isCareem

    const isMobileSubItemOpened = isMobileLanguageSwitcherOpen || isMobileCitySwitcherOpen

    const getMobileSubItemContent = () => {
        if (isMobileLanguageSwitcherOpen) {
            return <LangSwitcher setIsMobileLanguageSwitcherOpen={setIsMobileLanguageSwitcherOpen} />
        }
        if (isMobileCitySwitcherOpen) {
            return <CitySwitcher setIsSwitcherOpen={setIsMobileCitySwitcherOpen} />
        }
        return null
    }

    const logoClickHandler = () => {
        if (!isPage(Pages.browse)) pushWithCity('/cars')
    }

    return (
        <NavContainer
            className="navbarTop"
            onClick={() => datePickerOverlay && setDatepickerOverlay(false)}
            scrollDirection={scrollDirection}
        >
            <NavHeader>
                <BackButton showBackButton={showBackButton}>
                    {!showBackButton ? null : (
                        <a onClick={backHandler}>
                            <MiniArrowLeft />
                        </a>
                    )}
                </BackButton>

                <StyledNav>
                    <LogoWrapper onClick={logoClickHandler} isRTL={isRTL}>
                        {isRTL ? <LogoRTL /> : <Logo />}
                    </LogoWrapper>
                </StyledNav>
                <DesktopNav user={userData} isLanguageSwitchDisplayed={isLanguageSwitchDisplayed} />
                {menuButtonEnabled ? (
                    <MenuCont>
                        {isMobileNavigationOpen ? (
                            <NavIconContainer>
                                <div onClick={() => setIsMobileNavigationOpen(false)}>
                                    <Cross />
                                </div>
                            </NavIconContainer>
                        ) : (
                            <div
                                data-testid="hamburger-menu-open"
                                onClick={() => {
                                    setIsMobileNavigationOpen(true)
                                    trackEvent({
                                        action: GATrackingActions.BURGER_MENU_CLICK,
                                        category: GATrackingCategories.BURGER_MENU,
                                        value: {
                                            userId: userData?.userId || '',
                                            careemId: userData?.careemId || '',
                                            isDesktop: userData,
                                        },
                                    })
                                }}
                            >
                                <HamburgerMenu />
                            </div>
                        )}
                    </MenuCont>
                ) : (
                    <MenuCont />
                )}
            </NavHeader>

            {isMobileNavigationOpen && (
                <>
                    {isMobileSubItemOpened ? (
                        getMobileSubItemContent()
                    ) : (
                        <MobileNavigation
                            userData={userData}
                            queriedCity={queriedCity}
                            isLanguageSwitchDisplayed={isLanguageSwitchDisplayed}
                            setIsMobileCitySwitcherOpen={setIsMobileCitySwitcherOpen}
                            setIsMobileNavigationOpen={setIsMobileNavigationOpen}
                            setIsMobileLanguageSwitcherOpen={setIsMobileLanguageSwitcherOpen}
                        />
                    )}
                    <T />
                </>
            )}
        </NavContainer>
    )
}

const ListLink = styled.li`
    cursor: pointer;
`

const T = styled.div`
    left: 0;
    position: fixed;
    top: var(--navHeight);
    width: 100vw;
    height: 100vh;
    display: block;
    opacity: 1;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
`

const DesktopExternalLink = styled.a`
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
`

type MobileNavigationProps = {
    userData: MeResponse | undefined
    queriedCity: string
    isLanguageSwitchDisplayed: boolean
    setIsMobileCitySwitcherOpen: (isOpen: boolean) => void
    setIsMobileLanguageSwitcherOpen: (isOpen: boolean) => void
    setIsMobileNavigationOpen: (isOpen: boolean) => void
}

const MobileNavigation: FC<MobileNavigationProps> = ({
    userData,
    isLanguageSwitchDisplayed,
    queriedCity,
    setIsMobileNavigationOpen,
    setIsMobileCitySwitcherOpen,
    setIsMobileLanguageSwitcherOpen,
}) => {
    const { t } = useTranslation()

    const { currentLanguage } = useLanguage()
    const { isCareem } = useAuth()
    const { citySelector } = useCitySelector()
    const isCitySwitcherEnabled = citySelector.enabled && !isCareem

    const { query, asPath } = useRouter()
    const currentCity = query.city as string
    const { city: cityQuery, ...queryWithoutCity } = query
    const { openFreshChat } = useFreshChat()

    return (
        <MobileDropDown data-testid="hamburger-opened">
            {userData && (
                <>
                    <Link href={`/${queriedCity}/profile`} passHref legacyBehavior>
                        <LoginSection
                            onClick={() => {
                                trackEvent({
                                    action: 'Profile',
                                    category: 'MenuDR',
                                })
                            }}
                        >
                            <FlexContainer>
                                <Avatar>
                                    <ImageContainer src={`/rental/${placeholderAvatarImage}`} alt="" />
                                </Avatar>
                                <LoginTextContainer>
                                    <Headline>
                                        {userData.firstName} {userData.lastName}
                                    </Headline>
                                    {isCareem && <FullListText>{userData.email}</FullListText>}
                                </LoginTextContainer>
                            </FlexContainer>
                            <FlexContainer>
                                <MiniArrowRight />
                            </FlexContainer>
                        </LoginSection>
                    </Link>
                    <Separator />
                </>
            )}
            <MobileDropDownContentContainer>
                {!userData && (
                    <Link href={`/${queriedCity}/login?redirect=${asPath}`} passHref>
                        <MobileDropDownItemContainer>
                            <MobileDropdownItem>
                                <MobileDropDownIconContainer>
                                    <Login />
                                </MobileDropDownIconContainer>
                                <MobileDropDownMenuText>
                                    {t('menuitem.loginOrSignUp', 'Login or Sign up')}
                                </MobileDropDownMenuText>
                            </MobileDropdownItem>
                            <MiniArrowRight />
                        </MobileDropDownItemContainer>
                    </Link>
                )}
                <Link href={{ pathname: `/${queriedCity}/cars`, query: queryWithoutCity }} passHref>
                    <MobileDropDownItemContainer
                        onClick={() => {
                            trackEvent({
                                action: 'Browse',
                                category: 'MenuDR',
                            })
                            setIsMobileNavigationOpen(false)
                        }}
                    >
                        <MobileDropdownItem>
                            <MobileDropDownIconContainer>
                                <Search />
                            </MobileDropDownIconContainer>
                            <MobileDropDownMenuText>{t('menuitem.browse', 'See cars')}</MobileDropDownMenuText>
                        </MobileDropdownItem>
                        <MiniArrowRight />
                    </MobileDropDownItemContainer>
                </Link>
                {userData && (
                    <Link href={`/${queriedCity}/profile/myrentals`} passHref>
                        <MobileDropDownItemContainer>
                            <MobileDropdownItem
                                onClick={() => {
                                    trackEvent({
                                        action: GATrackingActions.BURGER_MENU_RENTALS,
                                        category: GATrackingCategories.BURGER_MENU,
                                        value: {
                                            userId: userData?.userId || '',
                                            careemId: userData?.careemId || '',
                                            isDesktop: userData,
                                        },
                                    })
                                }}
                            >
                                <MobileDropDownIconContainer>
                                    <Car />
                                </MobileDropDownIconContainer>
                                <MobileDropDownMenuText>{t('menuitem.myRentals', 'My rentals')}</MobileDropDownMenuText>
                            </MobileDropdownItem>
                            <MiniArrowRight />
                        </MobileDropDownItemContainer>
                    </Link>
                )}
                <a style={{ textDecoration: 'none' }}>
                    <MobileDropDownItemContainer
                        onClick={() => {
                            openFreshChat()
                            trackEvent({
                                action: 'Support',
                                category: 'MenuDR',
                            })
                        }}
                    >
                        <MobileDropdownItem>
                            <MobileDropDownIconContainer>
                                <Support />
                            </MobileDropDownIconContainer>
                            <MobileDropDownMenuText>{t('menuitem.support', 'Support')}</MobileDropDownMenuText>
                        </MobileDropdownItem>
                        <MiniArrowRight />
                    </MobileDropDownItemContainer>
                </a>
                <MobileDropDownItemContainer
                    onClick={() => {
                        isCitySwitcherEnabled && setIsMobileCitySwitcherOpen(true)
                    }}
                >
                    <MobileDropdownItem>
                        <MobileDropDownIconContainer>
                            <Poi />
                        </MobileDropDownIconContainer>
                        <MobileDropDownMenuText>{t(currentCity)}</MobileDropDownMenuText>
                    </MobileDropdownItem>
                    {isCitySwitcherEnabled && <MiniArrowRight />}
                </MobileDropDownItemContainer>
                {isLanguageSwitchDisplayed && (
                    <MobileDropDownItemContainer onClick={() => setIsMobileLanguageSwitcherOpen(true)}>
                        <MobileDropdownItem>
                            <MobileDropDownIconContainer>
                                <LanguageGlobe />
                            </MobileDropDownIconContainer>
                            <MobileDropDownMenuText>
                                {t('menuItem.language', 'Language')}:{' '}
                                {t(`headerLanguage${currentLanguage.name}`, currentLanguage.name)}
                            </MobileDropDownMenuText>
                        </MobileDropdownItem>
                        <MiniArrowRight />
                    </MobileDropDownItemContainer>
                )}
            </MobileDropDownContentContainer>
        </MobileDropDown>
    )
}

type DesktopNavProps = {
    user: MeResponse | undefined
    isLanguageSwitchDisplayed: boolean
}

const DesktopNav: FC<DesktopNavProps> = ({ user, isLanguageSwitchDisplayed }) => {
    const { t } = useTranslation()
    const {
        query,
        query: { city },
        asPath,
    } = useRouter()
    const { city: cityQuery, ...queryWithoutCity } = query
    const { openFreshChat } = useFreshChat()
    return (
        <DNav>
            <LeftSide>
                <Link href={{ pathname: `/${city}/cars`, query: queryWithoutCity }} passHref>
                    <ListLink>{t('desktopMenuItem.browse', 'Browse')}</ListLink>
                </Link>
                {user ? (
                    <Link data-testid={'navigation_rentals-link'} href={`/${city}/profile/myrentals`} passHref>
                        <ListLink>{t('desktopMenuItem.rentals', 'Rentals')}</ListLink>
                    </Link>
                ) : null}
                <DesktopExternalLink onClick={() => openFreshChat()}>
                    <ListLink>{t('desktopMenuItem.help', 'Help')}</ListLink>
                </DesktopExternalLink>
            </LeftSide>
            <RightSide>
                <li>
                    <CitySwitcher isDesktop />
                </li>
                {isLanguageSwitchDisplayed && (
                    <li>
                        <LangSwitcher isDesktop />
                    </li>
                )}
                {user ? (
                    <Link data-testid={'navigation_profile-link'} href={`/${city}/profile`} passHref>
                        <ListLink>
                            <RedText>
                                {t('desktopMenuItem.hello', {
                                    firstName: user?.firstName,
                                    defaultValue: 'Hello, {{firstName}}!',
                                })}
                            </RedText>
                        </ListLink>
                    </Link>
                ) : (
                    <Link data-testid={'navigation_login-link'} href={`/${city}/login?redirect=${asPath}`} passHref>
                        <ListLink>
                            <IconAndLink>
                                <LoginDesktop />
                                <RedText>{t('login', 'Login')}</RedText>
                            </IconAndLink>
                        </ListLink>
                    </Link>
                )}
            </RightSide>
        </DNav>
    )
}

const RedText = styled.p`
    color: var(--primaryColor);
`

const Headline = styled.h1`
    font-size: var(--size-24);
    font-weight: var(--weight-extraBold);
    color: black;
    margin: 0;
`

const FullListText = styled.p`
    font-weight: var(--weight-bold);
    font-size: 14px;
    margin: 0;
    margin-top: 4px;
    color: var(--primaryColor);
`

const LoginSection = styled.a`
    padding: 24px;
    display: flex;
    justify-content: space-between;
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Avatar = styled.div`
    width: 56px;
    height: 56px;
`
const ImageContainer = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
`
const LoginTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: 24px;
    margin-inline-end: 24px;
`
const Separator = styled.div`
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    padding: 24px -24px;
`

const DNav = styled.div`
    display: none;
    @media (min-width: ${size.laptop}) {
        display: flex;
        flex: 1;
        align-items: center;
        li {
            list-style: none;
            font-weight: bold;
            margin-inline-start: 2rem;
        }
    }
`

const LeftSide = styled.div`
    display: flex;
    flex: 1;
    list-style: none;
`

const RightSide = styled.div`
    display: flex;
    align-items: center;
    list-style: none;

    & > li {
        position: relative;
    }
`
const LogoWrapper = styled.a<{ isRTL: boolean }>`
    height: 24px;
    margin: ${(props) => (props.isRTL ? '0 0 10px 0' : '3px 0 0 0')};
`

const NavContainer = styled.div<{ scrollDirection: 'down' | 'up' }>`
    width: 100%;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    z-index: 100;
    position: fixed;
    background: var(--white);
    height: 66px;

    @media (min-width: ${size.laptop}) {
        height: var(--navHeight);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        box-shadow: none;
        position: sticky;
        top: ${(props) => (props.scrollDirection === 'down' ? '-84px' : '0px')};
        transition-property: all;
        transition-timing-function: cubic-bezier(1, 0.2, 0.2, 1);
        transition-duration: 500ms;
    }
`

const NavIconContainer = styled.div`
    display: flex;
    column-gap: 24px;
`

const BackButton = styled.div<{ showBackButton: boolean }>`
    min-width: 56px;
    @media (min-width: ${size.laptop}) {
        display: ${(props) => !props.showBackButton && 'none'};
    }
`

const MenuCont = styled.div`
    display: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 56px;
    @media (min-width: ${size.laptop}) {
        display: none;
    }
`

const StyledNav = styled.nav`
    display: flex;
    align-items: center;
`

const NavHeader = styled.div`
    width: ${size.container};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0 var(--padding);
    @media ${device.laptop} {
        padding: 0 var(--desktopPadding);
    }
`

const IconAndLink = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    & > p {
        margin-inline-start: 10px;
    }
`
